<template>
    <div class="flex flex-col items-center gap-2">
        <div class="w-full text-black">Profile visibility</div>
        <div v-if="success" class="font-medium text-sm text-gray-500">Saved</div>
        <div v-if="loading" class="w-4 h-4 text-gray-500">
            <LoadingSpinner />
        </div>
        <ProfileErrors v-if="errors" :errors="errors" />
        <div class="relative w-full cursor-pointer">
            <div class="bg-white px-4 py-2 rounded-xl border border-gray-200">
                <div class="flex gap-2" @click="toggleOpen">
                    <i class="bi bi-lock-fill"></i>
                    <div>
                        <div class="font-medium">{{ selected.text }}</div>
                        <div>{{ selected.description }}</div>
                    </div>
                    <i class="bi bi-chevron-down m-auto"></i>
                </div>
            </div>
            <div v-if="isOpen" class="absolute w-full pt-2">
                <div class="px-4 py-2 bg-white rounded-xl border border-gray-200 flex flex-col gap-2">
                    <div v-for="option in options" :key="option.text" class="flex gap-2" @click="handleSubmit(option.value)">
                        <i :class="`bi ${option.text === 'Private' ? 'bi-lock-fill' : 'bi-buildings'}`"></i>
                        <div>
                            <div class="font-medium">{{ option.text }}</div>
                            <div>{{ option.description }}</div>
                        </div>
                        <i v-if="option.value === value" class="bi bi-check-lg m-auto"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import { computed, ref } from "vue";

import ProfileErrors from "./ProfileErrors.vue";

const SUCCESS_FEEDBACK_MS = 3000;

const emit = defineEmits(["submit"]);
const props = defineProps({
    initialValue: String,
});

const options = [
    { value: false, text: "Private", description: "Only you have access" },
    { value: true, text: "Shared", description: "Your team members can view" },
];

const errors = ref(null);
const loading = ref(false);
const success = ref(false);
const value = ref(props.initialValue);
const selected = computed(() => options.find((option) => option.value === value.value));
const isOpen = ref(false);

function toggleOpen() {
    isOpen.value = !isOpen.value;
}

function handleSubmit(newValue) {
    toggleOpen();
    const loadingTimeout = setTimeout(() => {
        loading.value = true;
    }, 200);

    emit("submit", {
        field: "is_public",
        value: newValue,
        onSuccess: () => {
            clearTimeout(loadingTimeout);
            loading.value = false;
            success.value = true;
            value.value = newValue;
            setTimeout(() => (success.value = false), SUCCESS_FEEDBACK_MS);
        },
        onError: (errs) => {
            clearTimeout(loadingTimeout);
            errors.value = props.name in errs ? errs[props.name] : errs;
            loading.value = false;
        },
    });
}
</script>
