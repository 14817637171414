import { PANE_DATA } from "~vue/components/navigation/MainMenu.vue";
import { reactive } from "vue";

export const useSideBarStore = reactive({
    isOpen: false,
    pane: null,
    toggle(pane) {
        this.isOpen = !this.isOpen;
        this.pane = null;
        if (pane && PANE_DATA[pane]) {
            this.pane = pane;
        }
    },
});
