<template>
    <dialog ref="dialogElement" class="bg-white md:p-8 p-4 rounded-xl">
        <div class="flex flex-col gap-4 text-center w-full md:w-[600px]">
            <div class="text-xl font-bold">{{ title }}</div>
            <slot name="content"> </slot>
            <div v-if="error === errors.VALIDATION_ERROR" class="text-xs text-red-400">Summary cannot be empty.</div>
            <div class="mt-4">
                <button
                    type="button"
                    autofocus
                    class="rounded-xl text-white bg-valence-pink-600 hover:bg-valence-pink-600/80 font-bold text-sm py-3 px-6 capitalize"
                    @click="actionButtonCallback"
                >
                    {{ actionButtonLabel }}
                </button>
            </div>
            <i v-if="dismissable" class="bi bi-x absolute right-3 top-3 cursor-pointer" @click="dismiss"></i>
        </div>
    </dialog>
</template>

<script>
import { ref } from "vue";

const ERRORS = {
    VALIDATION_ERROR: "validation_error",
};

export default {
    name: "GenericDialog",
    emits: ["complete"],
    props: {
        title: String(""),
        actionButtonLabel: String(""),
        actionButtonCallback: Function(() => {}),
        summary: String(""),
        actionItems: Object({}),
        dismissable: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        return {
            dialogElement: ref(null),
            editedSummary: ref(props.summary),
            error: ref(null),
            actionItems: ref(props.actionItems),
        };
    },
    computed: {
        errors() {
            return ERRORS;
        },
    },
    methods: {
        dismiss() {
            this.dialogElement.close();
        },
        send() {
            if (this.editedSummary.length === 0) {
                this.error = ERRORS.VALIDATION_ERROR;
                return;
            }
            this.$emit("complete", this.editedSummary, this.actionItems);
        },
        open() {
            this.dialogElement.showModal();
        },
    },
};
</script>

<style>
dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
