<template>
    <div type="button" class="text-left flex flex-col gap-2 rounded-xl p-4 bg-white border border-gray-200">
        <button :title="open ? 'Show' : 'Hide'" class="flex flex-row gap-2 items-center" @click="toggle()">
            <div :class="hasData ? 'text-valence-light-blue-400' : 'text-gray-300'" class="shrink-0 bg-white rounded-full text-base">
                <i class="bi bi-check-circle-fill text-current"></i>
            </div>
            <div class="grow text-sm text-start capitalize" v-text="key_display"></div>
            <div class="ml-2 justify-self-end">
                <i v-if="!open" class="bi bi-chevron-right text-xs text-gray-700"></i>
                <i v-if="open" class="bi bi-chevron-down text-xs text-gray-700"></i>
            </div>
        </button>
        <div v-if="open">
            <div v-if="!isEditing">
                <div class="text-xs" v-text="profileDataText"></div>
                <div v-if="canEdit">
                    <div class="flex space-x-2 mt-3 w-full">
                        <button type="button" class="flex items-center justify-center py-2 px-2 border rounded-xl flex-grow" @click="isEditing = true">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 13.835H14" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M11 2.83511C11.2652 2.5699 11.6249 2.4209 12 2.4209C12.1857 2.4209 12.3696 2.45748 12.5412 2.52855C12.7128 2.59962 12.8687 2.70379 13 2.83511C13.1313 2.96643 13.2355 3.12234 13.3066 3.29392C13.3776 3.4655 13.4142 3.64939 13.4142 3.83511C13.4142 4.02083 13.3776 4.20473 13.3066 4.37631C13.2355 4.54789 13.1313 4.70379 13 4.83511L4.66667 13.1684L2 13.8351L2.66667 11.1684L11 2.83511Z"
                                    stroke="#9A9A9A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                        <button type="button" class="flex items-center justify-center py-2 px-2 border rounded-xl flex-grow text-md" @click="deleteProfileData()">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 4.50195H3.33333H14" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M5.33337 4.50163V3.16829C5.33337 2.81467 5.47385 2.47553 5.7239 2.22549C5.97395 1.97544 6.31309 1.83496 6.66671 1.83496H9.33337C9.687 1.83496 10.0261 1.97544 10.2762 2.22549C10.5262 2.47553 10.6667 2.81467 10.6667 3.16829V4.50163M12.6667 4.50163V13.835C12.6667 14.1886 12.5262 14.5277 12.2762 14.7778C12.0261 15.0278 11.687 15.1683 11.3334 15.1683H4.66671C4.31309 15.1683 3.97395 15.0278 3.7239 14.7778C3.47385 14.5277 3.33337 14.1886 3.33337 13.835V4.50163H12.6667Z"
                                    stroke="#9A9A9A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path d="M9.33337 7.83496V11.835" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.66663 7.83496V11.835" stroke="#9A9A9A" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <textarea v-model="profileDataText" class="w-full h-40 border border-gray-200 rounded-lg p-2"></textarea>
                <div class="flex space-x-2 mt-3 w-full">
                    <button type="button" class="flex items-center justify-center py-2 px-2 border rounded-xl flex-grow" @click="isEditing = !isEditing">Cancel</button>
                    <button type="button" class="flex items-center justify-center px-2 py-2 border rounded-xl flex-grow text-md bg-blue-500 text-white" @click="saveProfileData()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const DEFAULT_STRING = "Complete 5 conversations with Nadia to unlock this insight";
</script>

<script setup>
import { onMounted, ref, inject } from "vue";

import { getCookie } from "../../../utils";

const { emitter }  = inject('globalProperties');

const open = ref(false);
const isEditing = ref(false);
const hasData = ref(false);
const canEdit = ref(true);
const profileDataText = ref(DEFAULT_STRING);

const props = defineProps({
    id: Number,
    key_display: {
        type: String,
        default: () => "",
    },
    answer: {
        type: String,
        default: () => DEFAULT_STRING,
    },
});
function toggle() {
    open.value = !open.value;
    if (open.value) {
        markProfileDataRead();
    }
}

const updateProfileData = async () => {
    try {
        const response = await fetch(`/accounts/profile-data/${props.id}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                answer: profileDataText.value,
            }),
        });
        return response;
    } catch (error) {
        console.error("Failed to get profile notifications", error);
    }
};

const createProfileData = async () => {
    try {
        const response = await fetch("/accounts/profile-data/", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                profile_question_key: props.key_display,
                value: profileDataText.value,
            }),
        });
        return response;
    } catch (error) {
        console.error("Failed to get profile notifications", error);
    }
};

const updateNotificationCount = () => {
    document.dispatchEvent(new CustomEvent("valence:profileNotificationChange"));
};

const saveProfileData = async () => {
    isEditing.value = false;
    if (props.answer == profileDataText.value) {
        return;
    }

    let response;
    if (hasData.value) {
        response = await updateProfileData();
    } else {
        response = await createProfileData();
    }

    if (response && response.ok) {
        hasData.value = true;
        props.answer = profileDataText.value;
    }
};

const deleteProfileData = async () => {
    profileDataText.value = DEFAULT_STRING;
    hasData.value = false;
    try {
        const response = await fetch(`/accounts/profile-data/${props.id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });

        if (response.ok) {
            emitter.emit("inferred_profile_answer", {})
        }
    } catch (error) {
        console.error("Failed to get profile notifications", error);
    }
};

const markProfileDataRead = async () => {
    if (hasData.value) {
        try {
            await fetch(`/accounts/profile-notifications/${props.id}`, {
                method: "PUT",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            });
        } catch (error) {
            console.error("Failed to get profile notifications", error);
        }
        updateNotificationCount();
    }
};

onMounted(() => {
    hasData.value = props.answer;
    if (props.answer) {
        profileDataText.value = props.answer;
    }
});
</script>
