<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1166_409)">
            <path
                d="M12 17.25C11.9005 17.25 11.8052 17.2105 11.7348 17.1402C11.6645 17.0698 11.625 16.9745 11.625 16.875C11.625 16.7755 11.6645 16.6802 11.7348 16.6098C11.8052 16.5395 11.9005 16.5 12 16.5"
                stroke="currentColor"
                stroke-width="1.5"
            />
            <path
                d="M12 17.25C12.0995 17.25 12.1948 17.2105 12.2652 17.1402C12.3355 17.0698 12.375 16.9745 12.375 16.875C12.375 16.7755 12.3355 16.6802 12.2652 16.6098C12.1948 16.5395 12.0995 16.5 12 16.5"
                stroke="currentColor"
                stroke-width="1.5"
            />
            <path d="M12 13.5V5.25" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
            <path
                d="M12 23.25C18.213 23.25 23.25 18.213 23.25 12C23.25 5.787 18.213 0.75 12 0.75C5.787 0.75 0.75 5.787 0.75 12C0.75 18.213 5.787 23.25 12 23.25Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-miterlimit="10"
            />
        </g>
        <defs>
            <clipPath id="clip0_1166_409">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
