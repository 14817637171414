<template>
    <div class="flex flex-col gap-2">
        <template v-if="hasSessions">
            <template v-for="bundle in coaching_sessions" :key="bundle.label">
                <div class="text-xs uppercase font-medium mt-2 first:mt-0" v-text="bundle.label"></div>
                <template v-for="session in bundle.sessions" :key="session.hashid">
                    <SidebarCard
                        :title="session.title"
                        :url="`${session.return_url}?from=past_conversations_click`"
                        button-title="Resume conversation"
                        :is-active="activeHashid === session.hashid"
                    />
                </template>
            </template>
        </template>
        <div v-else>You have no past conversations.</div>
    </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import { computed, onMounted, onUnmounted, ref } from "vue";

import SidebarCard from "./SidebarCard.vue";

const activeHashid = ref(null);
const routeRegex = /coach\/(.*)\//;
const props = defineProps({
    coaching_sessions: {
        type: Array,
        default: () => [],
    },
});

function checkActiveConversation(path) {
    const match = path.match(routeRegex);

    if (match && match.length > 1) {
        activeHashid.value = match[1];
        return;
    }

    activeHashid.value = null;
}

const removeRouteCheck = router.on("start", (event) => {
    const currentPath = event.detail.visit.url.pathname;
    checkActiveConversation(currentPath);
});

const hasSessions = computed(() => {
    for (const bundle of props.coaching_sessions) {
        if (Array.isArray(bundle.sessions) && bundle.sessions.length > 0) {
            return true;
        }
    }

    return false;
});

onMounted(() => {
    checkActiveConversation(window.location.pathname);
});
onUnmounted(() => removeRouteCheck());
</script>
