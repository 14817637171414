<template>
    <div class="grow flex flex-col gap-4 justify-between">
        <div class="grow flex flex-col gap-4">
            <section v-if="isValencer" id="profile-is-public" class="flex flex-col gap-2 text-sm -mb-2">
                <ProfilePrivateToggle :initial-value="is_public" @submit="updateProfile" />
            </section>
            <section id="profile-user-data" class="flex flex-col gap-2 text-sm -mb-2">
                <template v-for="entry in user_profile_entries" :key="entry.key">
                    <ProfileUserEntry v-bind="entry" />
                </template>
            </section>
            <div class="flex flex-col gap-2">
                <ProfileDetailForm placeholder="Director" label="Role" name="job_title" :initial-value="job_title" type="text" @submit="updateProfile" />
                <ProfileDetailForm
                    label="Experience Level"
                    name="job_experience"
                    :options="job_experience_choices"
                    :initial-value="job_experience"
                    type="select"
                    @submit="updateProfile"
                />

                <ProfileDetail :checked="true">
                    <template #content>
                        <dl>
                            <dt class="text-sm">Organization</dt>
                            <dd class="text-xs" v-text="$organizationDetails.value.name"></dd>
                        </dl>
                    </template>
                </ProfileDetail>

                <ProfileDetail :checked="has_leadership_profile">
                    <template #content>
                        <div class="text-sm">Leadership Profile</div>
                    </template>
                    <template #action>
                        <div class="shrink-0">
                            <Link :href="leadership_profile_url">
                                <i class="bi bi-chevron-right text-xs text-gray-700"></i>
                            </Link>
                        </div>
                    </template>
                </ProfileDetail>
            </div>
        </div>
        <ProfileLanguageForm :options="language_choices" :initial-value="default_language" @submit="updateProfile" />
        <ProfileDetailForm
            label="Cookies Preference"
            name="cookie_preference"
            :options="cookie_preferences_choices"
            :initial-value="cookie_consent_choice"
            type="select"
            @submit="updateCookiePreferences"
        />
    </div>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { Link } from "@inertiajs/vue3";
import { useUserStore } from "~vue/stores/userStore";
import { computed } from "vue";

import ProfileDetail from "./ProfileDetail.vue";
import ProfileDetailForm from "./ProfileDetailForm.vue";
import ProfileLanguageForm from "./ProfileLanguageForm.vue";
import ProfilePrivateToggle from "./ProfilePrivateToggle.vue";
import ProfileUserEntry from "./ProfileUserEntry.vue";

defineProps({
    default_language: {
        type: String,
        default: () => undefined,
    },
    language_choices: {
        type: Array,
        default: () => [],
    },
    job_title: {
        type: String,
        default: () => undefined,
    },
    job_experience: {
        type: String,
        default: () => undefined,
    },
    job_experience_choices: {
        type: Array,
        default: () => [],
    },
    cookie_consent_choice: {
        type: String,
        default: () => undefined,
    },
    cookie_preferences_choices: {
        type: Array,
        default: () => [],
    },
    has_leadership_profile: {
        type: Boolean,
        default: () => false,
    },
    leadership_profile_url: {
        type: String,
        default: () => "/",
    },
    user_profile_entries: {
        type: Array,
        default: () => [],
    },
    is_public: {
        type: Boolean,
        default: () => false,
    },
});

const isValencer = computed(() => useUserStore.isValencer);

async function updateProfile({ field, value, onSuccess, onError }) {
    try {
        const response = await fetch("/accounts/profile/", {
            method: "POST",
            body: JSON.stringify({
                [field]: value,
                detail: field,
            }),
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });

        const body = await response.json();
        if (!response.ok) {
            onError(["Failed to save profile."]);
            return;
        }

        if (!body.success) {
            onError(body.errors);
            return;
        }

        onSuccess();
    } catch (e) {
        onError(["Failed to save profile."]);
        if (window.Sentry) {
            window.Sentry.captureException(e);
        }
    }
}

async function updateCookiePreferences({ value, onSuccess, onError }) {
    const response = await fetch("/accounts/cookie-preferences/", {
        method: "PUT",
        body: JSON.stringify({ choice: value, method: "profile" }),
        headers: { "X-CSRFToken": getCookie("csrftoken"), "Content-Type": "application/json" },
    });
    if (response.status != 204) {
        console.error("Failed to update cookie preferences");
        onError(["Failed to update cookie preferences."]);
        return null;
    }
    localStorage.setItem("cookie_consent", `persisted`);
    onSuccess();
    return response;
}
</script>
