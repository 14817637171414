<template>
    <ProfileDetail :checked="checked">
        <template #content="contentProps">
            <form ref="formRef" class="flex items-baseline" @submit.prevent="submit">
                <div class="flex flex-row items-end grow">
                    <div class="flex flex-col grow self-stretch gap-2">
                        <label :for="name" class="cursor-pointer text-sm" @click="contentProps.toggle" v-text="label"></label>
                        <template v-if="contentProps.open">
                            <input
                                v-if="type === 'text'"
                                :id="name"
                                v-model="value"
                                :disabled="loading"
                                required
                                type="text"
                                class="border-b border-b-gray-300 w-full text-xs"
                                :name="name"
                                :placeholder="placeholder"
                            />
                            <select
                                v-else-if="type === 'select'"
                                :id="name"
                                v-model="value"
                                :disabled="loading"
                                required
                                :name="name"
                                class="bg-white border-b border-b-gray-300 w-full text-xs"
                            >
                                <template v-for="opt in options" :key="opt[0]">
                                    <option class="text-neutral-700 bg-white" :value="opt[0]" :selected="opt[0] === value" v-text="opt[1]"></option>
                                </template>
                            </select>
                            <div v-if="errors">
                                <ProfileErrors :errors="errors" />
                            </div>
                        </template>
                    </div>
                </div>

                <div class="relative top-4">
                    <i v-if="showSuccess" class="bi bi-check text-base text-green-500"></i>
                    <div v-if="loading" class="w-3 h-3 text-gray-400">
                        <LoadingSpinner />
                    </div>
                </div>
            </form>
        </template>
        <template #action="actionProps">
            <div class="shrink-0 pl-2">
                <button :title="actionProps.open ? 'Close' : 'Edit'" type="button" @click="actionProps.toggle">
                    <i v-if="actionProps.open" class="bi bi-chevron-down text-xs text-gray-700"></i>
                    <i v-else class="bi bi-chevron-right text-xs text-gray-700"></i>
                </button>
            </div>
        </template>
    </ProfileDetail>
</template>

<script setup>
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import debounce from "lodash.debounce";
import { ref, watch } from "vue";

import ProfileDetail from "./ProfileDetail.vue";
import ProfileErrors from "./ProfileErrors.vue";

const emit = defineEmits(["submit"]);
const props = defineProps({
    placeholder: String,
    initialValue: String,
    type: String,
    label: String,
    name: String,
    options: Array,
});

const DEBOUNCE_MS = 500;
const SUCCESS_FEEDBACK_MS = 3000;

const loading = ref(false);
const errors = ref(null);
const showSuccess = ref(false);
const checked = ref(!!props.initialValue);
const formRef = ref(null);
const value = ref(props.initialValue);

watch(
    value,
    debounce(() => {
        formRef.value.requestSubmit();
    }, DEBOUNCE_MS),
);

function submit() {
    const loadingTimeout = setTimeout(() => (loading.value = true), 200);

    emit("submit", {
        field: props.name,
        value: value.value,
        onSuccess: () => {
            clearTimeout(loadingTimeout);
            loading.value = false;
            if (!checked.value) {
                checked.value = true;
            }

            showSuccess.value = true;
            setTimeout(() => (showSuccess.value = false), SUCCESS_FEEDBACK_MS);
        },
        onError: (errs) => {
            clearTimeout(loadingTimeout);
            errors.value = props.name in errs ? errs[props.name] : errs;
            loading.value = false;
        },
    });
}
</script>
