<template>
    <div id="profile-menu-popover" ref="popover" class="inset-[unset] absolute w-[calc(280px)] bottom-[4em] left-[1em] p-4 shadow-md border border-gray-50 rounded-xl" popover="">
        <div class="flex flex-col gap-5">
            <template v-for="item in SETTINGS_MENU" :key="item.id">
                <button
                    v-if="item.type === 'pane'"
                    type="button"
                    class="cursor-pointer text-base text-valence-grey-800 hover:text-valence-grey-800/80 flex flex-row items-center gap-5 leading-none"
                    @click="$emit('openPane', item)"
                >
                    <span class="h-4 w-4"><component :is="item.icon" /></span>
                    {{ item.title }}
                </button>
                <a
                    v-else-if="item.type === 'url'"
                    :href="item.url"
                    class="text-base text-valence-grey-800 hover:text-valence-grey-800/80 flex flex-row items-center gap-4 leading-none"
                >
                    <span class="h-4 w-4"><component :is="item.icon" /></span>
                    {{ item.title }}
                </a>
            </template>
        </div>
    </div>
    <button
        popovertarget="profile-menu-popover"
        popovertargetaction="toggle"
        title="View menu"
        type="button"
        :class="{ '!flex': isOpen, 'hidden !md:flex': !isOpen }"
        class="shrink-0 p-5 w-full border-t border-t-gray-100 hover:bg-gray-50/80 text-left flex flex-row items-center gap-3 peer-popover-open:bg-gray-50"
    >
        <div class="w-10 h-10 shrink-0">
            <i class="bi-person-circle text-3xl text-valence-grey"></i>
        </div>
        <div class="text-valence-grey overflow-hidden grow">
            <p class="text-sm font-medium">{{ $userDetails.value.first_name }} {{ $userDetails.value.last_name }}</p>
            <p :title="$userDetails.value.email" class="text-xs text-ellipsis overflow-hidden" v-text="$userDetails.value.email"></p>
        </div>
    </button>
</template>

<script setup>
import AlertCircle from "~vue/icons/AlertCircle.vue";
import Logout from "~vue/icons/Logout.vue";
import { isInIFrame } from "~vue/utils.js";
import { inject, ref } from "vue";

const globalProperties = inject("globalProperties");

defineProps({
    isOpen: {
        type: Boolean,
        default: () => false,
    },
});

defineExpose({
    hidePopover,
});

defineEmits(["openPane"]);

const SETTINGS_MENU = [
    {
        id: "feedback",
        title: "Report an Issue",
        type: "url",
        url: globalProperties.$feedbackMailto,
        icon: AlertCircle,
    },
    {
        id: "logout",
        title: "Logout",
        type: "url",
        url: "/accounts/logout/",
        icon: Logout,
    },
];

if (isInIFrame()) {
    SETTINGS_MENU.pop();
}

const popover = ref(null);

function hidePopover() {
    popover.value.hidePopover();
}
</script>
