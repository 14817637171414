<template>
    <div class="flex flex-col m-px" :class="{ 'gap-px': accordionItems.length > 1 }">
        <details v-for="(item, index) of accordionItems" :key="item.trigger" class="text-base items-center gap-5 leading-none group bg-white">
            <summary
                :ref="(el) => setAccordionRef(el, index)"
                class="list-none cursor-pointer flex justify-between items-center gap-5 p-2 rounded-lg min-h-12 hover:bg-valence-panehover"
                @keydown="(e) => handleKeyDown(e, index)"
            >
                <div v-if="$slots.trigger" class="grow">
                    <slot name="trigger" :title="item.title"></slot>
                </div>
                <i class="bi bi-chevron-right group-open:rotate-90 transition-all"></i>
            </summary>
            <div v-if="$slots.content" class="p-2 pt-0 overflow-hidden group-open:overflow-auto max-h-0 group-open:max-h-full transition-all">
                <slot name="content" :content="item.content"></slot>
            </div>
        </details>
    </div>
</template>

<script setup>
/**
 * BaseAccordion component with some styles, using html native <details> element.
 * Implements optional keyboard accessibility as defined by https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
 * - Does not support circular navigation, i.e. pressing ArrowUp on the first item will not focus the last item.
 */
import { ref } from "vue";

defineProps({
    // accordionItems is currently expected to be of type Array<{title: string, content: string }>
    // Typescript for these situations would be useful
    accordionItems: {
        type: Array,
        default: () => [],
    },
});

const accordionRefs = ref([]);

const setAccordionRef = (el, index) => {
    accordionRefs.value[index] = el;
};

const handleKeyDown = (event, index) => {
    const code = event.code;
    switch (code) {
        case "ArrowUp":
            if (index <= 0) return;
            accordionRefs.value[index - 1]?.focus();
            break;
        case "ArrowDown":
            if (index < 0 || index === accordionRefs.value.length - 1) return;
            accordionRefs.value[index + 1]?.focus();
            break;
        case "Home":
            accordionRefs.value[0]?.focus();
            break;
        case "End":
            accordionRefs.value[accordionRefs.value.length - 1]?.focus();
            break;
        default:
            break;
    }
};
</script>
