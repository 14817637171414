<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1166_400)">
            <path d="M0.75 12.0039H16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.75 15.7539L16.5 12.0039L12.75 8.25391" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M3.30664 16.5995C4.33869 18.7197 6.05435 20.4307 8.17729 21.4571C10.3002 22.4835 12.7068 22.7654 15.0095 22.2574C17.3122 21.7494 19.3768 20.4811 20.8709 18.6568C22.365 16.8326 23.2015 14.5585 23.2457 12.2008C23.2899 9.84324 22.5393 7.53937 21.1147 5.66033C19.6901 3.78129 17.6745 2.43648 15.3925 1.8425C13.1105 1.24852 10.695 1.43995 8.53505 2.38597C6.37511 3.33198 4.59648 4.97751 3.48564 7.05749"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1166_400">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
