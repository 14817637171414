import { reactive } from "vue";

export const useUserStore = reactive({
    isValencer: false,
    setIsValencer(isValencer) {
        this.isValencer = isValencer;
    },
    teamMembers: [],
    setTeamMembers(teamMembers) {
        this.teamMembers = teamMembers;
    },
});
