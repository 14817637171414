<template>
    <form ref="formRef" class="flex flex-col items-center gap-2" @submit.prevent="handleSubmit">
        <div v-if="success" class="font-medium text-sm text-gray-500">Saved</div>
        <div v-if="loading" class="w-4 h-4 text-gray-500">
            <LoadingSpinner />
        </div>
        <ProfileErrors v-if="errors" :errors="errors" />
        <div class="block w-full">
            <div class="w-full flex flex-col gap-1">
                <select
                    id="default_language"
                    v-model="value"
                    :disabled="loading"
                    name="default_language"
                    class="bg-white border border-gray-200 text-sm leading-none rounded-lg p-4 placeholder:text-valence-grey-800/75 text-valence-grey-800 disabled:text-valence-grey-800/75"
                >
                    <template v-for="(opt, idx) in options" :key="idx">
                        <option class="text-valence-grey-800 bg-white" :value="opt[0]" :selected="opt[0] === value" v-text="opt[1]"></option>
                    </template>
                </select>
            </div>
        </div>
    </form>
</template>

<script setup>
import LoadingSpinner from "~vue/icons/LoadingSpinner.vue";
import debounce from "lodash.debounce";
import { ref, watch } from "vue";

import ProfileErrors from "./ProfileErrors.vue";

const DEBOUNCE_MS = 500;
const SUCCESS_FEEDBACK_MS = 3000;

const emit = defineEmits(["submit"]);
const props = defineProps({
    initialValue: String,
    options: Array,
});

const formRef = ref(null);
const errors = ref(null);
const loading = ref(false);
const success = ref(false);
const value = ref(props.initialValue);

watch(
    value,
    debounce(() => {
        formRef.value.requestSubmit();
    }, DEBOUNCE_MS),
);

function handleSubmit() {
    const loadingTimeout = setTimeout(() => {
        loading.value = true;
    }, 200);

    emit("submit", {
        field: "default_language",
        value: value.value,
        onSuccess: () => {
            clearTimeout(loadingTimeout);
            loading.value = false;
            success.value = true;
            setTimeout(() => (success.value = false), SUCCESS_FEEDBACK_MS);
        },
        onError: (errs) => {
            clearTimeout(loadingTimeout);
            errors.value = props.name in errs ? errs[props.name] : errs;
            loading.value = false;
        },
    });
}
</script>

<style scoped>
select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2213%22%20height%3D%228%22%20viewBox%3D%220%200%2013%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.75%201.665L2.1645%200.25L6.752%204.9195L11.3355%200.25L12.75%201.665L6.752%207.75L0.75%201.665Z%22%20fill%3D%22%231F2937%22%20fill-opacity%3D%220.6%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
}
</style>
