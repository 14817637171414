<template>
    <section :class="open ? 'items-baseline' : 'items-center'" class="flex flex-row gap-2 rounded-xl p-4 bg-white border border-gray-200">
        <div :class="checked ? 'text-valence-light-blue-400' : 'text-gray-300'" class="shrink-0 bg-white rounded-full text-base">
            <i class="relative top-0.5 bi bi-check-circle-fill text-current"></i>
        </div>
        <div class="grow text-valence-grey-800">
            <slot name="content" :open="open" :toggle="toggle"></slot>
        </div>
        <slot name="action" :open="open" :toggle="toggle"></slot>
    </section>
</template>

<script setup>
import { ref } from "vue";
const open = ref(false);

function toggle() {
    open.value = !open.value;
}
defineProps({
    checked: {
        type: Boolean,
        default: () => false,
    },
});
</script>
