<template>
    <div
        class="full-height-dynamic flex flex-col xl:flex-row min-w-full relative overflow-y-auto md:overflow-y-hidden"
        :class="{ 'fixed overflow-y-hidden': isOpen, 'bg-valence-blue-mid/45': !isHomePage, mainBackground: isHomePage }"
    >
        <main class="flex flex-col md:flex-row w-full overflow-y-visible md:overflow-y-auto h-full">
            <LeftSidebar :is-open="isOpen" @toggle="toggle" />
            <div class="relative grow flex overflow-hidden">
                <button
                    type="button"
                    :title="isOpen ? 'Close sidebar' : 'Open sidebar'"
                    class="z-50 hidden md:block absolute top-5 left-5 hover:text-valence-grey-800/75 hover:bg-white/50 rounded-md p-2 transition-color duration-150 ease-in"
                    @click="toggle"
                >
                    <i v-if="isOpen" class="bi bi-chevron-double-left h-5 w-5 text-xl"></i>
                    <i v-else class="bi bi-list h-5 w-5 text-xl"></i>
                </button>
                <div class="flex flex-col grow overflow-hidden">
                    <slot />
                </div>
            </div>
        </main>
    </div>
    <SessionExpirationDialog />
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import LeftSidebar from "~vue/components/navigation/LeftSidebar.vue";
import SessionExpirationDialog from "~vue/SessionExpirationDialog.vue";
import { useSideBarStore } from "~vue/stores/sideBarStore";
import { useUserStore } from "~vue/stores/userStore";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
    // from middleware
    user_details: {
        type: Object,
        default: () => ({}),
    },
});

const isHomePage = ref(router?.page.component === "ChatHome");

onMounted(() => {
    router.on("navigate", ({ detail }) => {
        isHomePage.value = detail.page.component === "ChatHome";
    });
});

watch(
    () => props.user_details,
    ({ is_valencer }) => {
        useUserStore.setIsValencer(is_valencer);
    },
    { immediate: true },
);

const isOpen = computed(() => useSideBarStore.isOpen);
const toggle = () => useSideBarStore.toggle();
</script>

<style scoped>
.mainBackground {
    --circle-size: 40vw;
    background: radial-gradient(circle var(--circle-size) at 20% 0%, #fe28901f, #ffffff00), radial-gradient(circle var(--circle-size) at 80% 0%, #003fde1f, #ffffff00);
}
</style>
